import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Hero } from "@components/Hero/Hero";

export const ReportsHero = () => {
	const props = {
		title: 'Simplify and safeguard your research',
		description: 'Keep your research findings safe and centralized with user-friendly report and export options.',
		image: (
			<StaticImage
				loading="eager"
				src="../../_assets/protocol-library-product.png"
				alt="Genemod Reports Hero Image"
			/>
		)
	}

	return <Hero {...props} heroTextContainerSize="small" />
};
